import Router from '@/router';
import { BrowserRouter } from 'react-router-dom';
import Footer from '@/component/footer';
import Nav from '@/component/nav';

function Layout() {
  return (
    <BrowserRouter>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Nav />
          <Router />
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Layout;
