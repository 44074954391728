import { Navigation } from '@/common/nav/header';
import { Popover } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { HeartIcon } from '@heroicons/react/24/solid';
import { Link, NavLink } from 'react-router-dom';

import LOGO from '@/asset/img/logo.webp';

interface Props {
  navs: Navigation[];
  nya: () => void;
}

function DesktopNav({ navs, nya }: Props) {
  return (
    <div className="bg-gray-900 py-6">
      <nav className="relative mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6" aria-label="Global">
        <div className="flex flex-1 items-center">
          <div className="flex w-full items-center justify-between md:w-auto">
            <Link to="/">
              <span className="sr-only">Workflow</span>
              <img className="h-8 w-auto sm:h-10" src={LOGO} alt={'Nyawork'} />
            </Link>
            <div className="-mr-2 flex items-center md:hidden">
              <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="hidden space-x-8 md:ml-10 md:flex">
            {navs.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className={({ isActive }) =>
                  `rounded px-3 py-1 text-base font-medium text-white transition-colors hover:text-gray-300 ${
                    isActive ? 'bg-primary bg-opacity-90' : ''
                  }`
                }
              >
                {item.name}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="hidden md:flex md:items-center md:space-x-6">
          <div
            onClick={nya}
            className="inline-flex cursor-pointer items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white transition-colors hover:bg-gray-700"
          >
            Say Nya <HeartIcon className={'h-4 w-4 fill-red-600'} />
          </div>
        </div>
      </nav>
    </div>
  );
}

export default DesktopNav;
