import { Route, Routes } from 'react-router-dom';

import Home from '@/page/home';
import Team from '@/page/team';
import Service from '@/page/service';
import Privacy from '@/page/privacy';
import Contact from '@/page/contact';
import NotFound from '@/page/404';

function Router() {
  return (
    <Routes>
      <Route path={'/'} element={<Home />} />
      <Route path={'/team'} element={<Team />} />
      <Route path={'/service'} element={<Service />} />
      <Route path={'/privacy'} element={<Privacy />} />
      <Route path={'/contact'} element={<Contact />} />
      <Route path={'*'} element={<NotFound />} />
    </Routes>
  );
}

export default Router;
