import { Navigation } from '@/common/nav/header';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { HeartIcon } from '@heroicons/react/24/solid';
import LOGO from '@/asset/img/logo.webp';

interface Props {
  navs: Navigation[];
  nya: () => void;
}

function MobileNav({ navs, nya }: Props) {
  return (
    <Transition
      as={Fragment}
      enter="duration-150 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top transform p-2 transition md:hidden">
        <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
          <div className="flex items-center justify-between px-5 pt-4">
            <div>
              <img className="h-8 w-auto" src={LOGO} alt={'Nyawork'} />
            </div>
            <div className="-mr-2">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="pb-6 pt-5">
            <div className="space-y-1 px-2">
              {navs.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="mt-6 px-5">
              <div
                onClick={nya}
                className="block w-full cursor-pointer rounded-md bg-gradient-to-r from-primary to-cyan-600 px-4 py-3 text-center font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700"
              >
                Say Nya{' '}
                <span className={'inline-flex'}>
                  <HeartIcon className={'h-4 w-4 fill-red-600'} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  );
}

export default MobileNav;
