import type { ServiceProps } from '@/component/servicesList';

const ServiceCard = (service: ServiceProps) => (
  <a
    key={service.title}
    href={service.href}
    className="flex flex-col overflow-hidden rounded-lg shadow-lg transition hover:-translate-y-1 hover:shadow-xl"
  >
    <div className="flex flex-1 flex-col justify-between bg-white p-6">
      <div className="flex-1">
        <div className="mt-2 block">
          <p className="text-xl font-semibold text-gray-900">{service.title}</p>
          <p className="mt-3 text-base text-gray-500">{service.summary}</p>
        </div>
      </div>
      <div className="mt-6 flex items-center">
        {service.logo && (
          <div className="flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={service.logo} alt={service.title} />
          </div>
        )}
        <div className="ml-3">
          <div className="flex space-x-1 text-sm text-gray-500">
            <span>Since</span>
            <time dateTime={service.date}>{service.date}</time>
          </div>
        </div>
      </div>
    </div>
  </a>
);

interface ServicesPartProps {
  services: ServiceProps[];
}
const ServicesPart = ({ services }: ServicesPartProps) => (
  <div className="mx-auto grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
    {services.map((service) => (
      <ServiceCard key={service.title} {...service} />
    ))}
  </div>
);

export default ServicesPart;
