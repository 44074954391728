const BackgroundDots = () => (
  <>
    <svg
      className="absolute left-full translate-x-1/2 transform"
      width={404}
      height={404}
      fill="none"
      viewBox="0 0 404 404"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="85737c0e-0916-41d7-917f-596dc7edfa27"
          x={0}
          y={0}
          width={20}
          height={20}
          patternUnits="userSpaceOnUse"
        >
          <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
    </svg>
    <svg
      className="absolute bottom-0 right-full -translate-x-1/2 transform"
      width={404}
      height={404}
      fill="none"
      viewBox="0 0 404 404"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="85737c0e-0916-41d7-917f-596dc7edfa27"
          x={0}
          y={0}
          width={20}
          height={20}
          patternUnits="userSpaceOnUse"
        >
          <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
    </svg>
  </>
);

const submitContactForm = (ev: any) => {
  ev.preventDefault();

  // Parse values to email format

  const emailSubject = `[Contact] ${ev.target.subject.value}`;
  const emailBody = `Hello Nyawork,

I'm ${ev.target.firstName.value} ${ev.target.lastName.value} from ${ev.target.company.value} trying to contact.

${ev.target.message.value}

Best regards,

---
${ev.target.firstName.value} ${ev.target.lastName.value}
${ev.target.email.value}
${ev.target.company.value}
`;

  // Open new page
  window.open(`mailto:support@nya.work?subject=${emailSubject}&body=${encodeURIComponent(emailBody)}`);
};

const ContactForm = () => (
  <div className="mt-12">
    <form className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" onSubmit={submitContactForm}>
      <div>
        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
          First name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="firstName"
            id="first-name"
            autoComplete="given-name"
            className="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-primary focus:ring-primary"
            required
          />
        </div>
      </div>
      <div>
        <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
          Last name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="lastName"
            id="last-name"
            autoComplete="family-name"
            className="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-primary focus:ring-primary"
            required
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
          Company
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="company"
            id="company"
            autoComplete="organization"
            className="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-primary focus:ring-primary"
            required
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            className="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-primary focus:ring-primary"
            required
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
          Subject
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="subject"
            autoComplete="subject"
            className="block w-full rounded-md border-gray-300 px-4 py-3 shadow-sm focus:border-primary focus:ring-primary"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
          Message
        </label>
        <div className="mt-1">
          <textarea
            id="message"
            name="message"
            rows={4}
            className="block w-full rounded-md border border-gray-300 px-4 py-3 shadow-sm focus:border-primary focus:ring-primary"
            required
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <button
          className={`inline-flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm transition-opacity duration-300 hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}
          type={'submit'}
        >
          Let's talk
        </button>
      </div>
    </form>
  </div>
);

const Contact = () => (
  <div className="overflow-hidden bg-white px-4 py-16 sm:px-6 lg:px-8 lg:py-24">
    <div className="relative mx-auto max-w-xl">
      <BackgroundDots />
      <div className={'text-center'}>
        <h2 className={'text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'}>Contact Us</h2>
      </div>
      <ContactForm />
    </div>
  </div>
);

export default Contact;
