import LOGO from '@/asset/img/logo.webp';
import { Link } from 'react-router-dom';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';

import { footerNavigation } from '@/common/nav/footer';

function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    setIsVisible(window.scrollY > 700);
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`transition-visibility fixed bottom-8 right-8 z-50 h-12 w-12 cursor-pointer rounded bg-primary text-white duration-300 ${
        isVisible ? 'visible opacity-100' : 'invisible opacity-0'
      }`}
      onClick={toTop}
    >
      <ChevronUpIcon />
    </div>
  );
}

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className={'sr-only'}>
        Footer
      </h2>
      <div className="mx-auto max-w-md px-4 pt-12 sm:max-w-7xl sm:px-6 lg:px-8 lg:pt-16">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img className="h-10" src={LOGO} alt={'Nyawork'} />
            <p className="text-base text-gray-500">
              Together we code &amp; host the future of world. Build with <span className="text-red-500">❤</span> by
              Nyawork.
            </p>
            <div className="flex space-x-6">
              {footerNavigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 md:grid-cols-4 xl:col-span-2 xl:mt-0">
            {footerNavigation.links.map((linkGroup) => (
              <div key={linkGroup.name} className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">{linkGroup.name}</h3>
                <ul className="mt-4 space-y-4">
                  {linkGroup.member.map((item) => (
                    <li key={item.name} className={'flex flex-row'}>
                      {!item.href ? (
                        <span className="text-base text-gray-400">{item.name}</span>
                      ) : item.href.includes(':') ? (
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      ) : (
                        <Link to={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </Link>
                      )}
                      {item.tag && (
                        <span className="ml-2 flex items-center rounded-full bg-primary px-2 py-0.5 text-xs font-medium text-white">
                          {item.tag}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 py-8">
          <p className="text-base text-gray-400 xl:text-center">&copy; {year} Nyawork. All rights reserved.</p>
        </div>
      </div>
      <BackToTop />
    </footer>
  );
}

export default Footer;
