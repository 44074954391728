import { ChevronRightIcon } from '@heroicons/react/24/solid';
import CloudIllustration from '@/asset/img/home/cloud-illustration.webp';

function Hero() {
  return (
    <div className="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
            <div className="lg:py-24">
              <a
                className="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                href="https://nya.one"
              >
                <span className="rounded-full bg-gradient-to-r from-primary to-cyan-600 px-3 py-0.5 text-xs font-semibold uppercase leading-5 tracking-wide text-white">
                  Community Opening
                </span>
                <span className="ml-4 text-sm">Join now</span>
                <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
              </a>
              <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                <span className="block">Self-host enthusiasts</span>
                <span className="block bg-gradient-to-r from-primary to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">
                  &amp; full-stack devs
                </span>
              </h1>
              <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                Nyawork consists of full-stack developers passionate with self-hosting services and FOSS. We construct
                infrastructures, we host services, we build platforms.
              </p>
            </div>
          </div>
          <div className="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
            <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
              <img
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={CloudIllustration}
                alt={'Nyawork Cloud'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
