import NyaOne from '@/asset/img/service/nyaone.webp';
// import NyaPictures from '@/asset/img/service/nyapictures.webp';
import NyaCodes from '@/asset/img/service/nyacodes.webp';
import Eihei from '@/asset/img/service/eihei.webp';
// import Acolicy from "@/asset/img/service/acolicy.webp";
// import Xio from "@/asset/img/service/xio.webp";
import NyaShow from '@/asset/img/service/nyashow.webp';
import NyaDisk from '@/asset/img/service/nyadisk.webp';
import Lemonadev from '@/asset/img/service/lemonadev.webp';

export interface ServiceProps {
  title: string;
  href: string;
  date: string;
  category: string;
  summary: string;
  logo: any;
}

export const OpenServices: ServiceProps[] = [
  {
    title: 'NyaOne',
    href: 'https://nya.one/',
    date: '2020-06-04',
    category: 'Social',
    summary: 'Welcome to NyaOne, have a good time ♥',
    logo: NyaOne,
  },
  {
    title: 'Eihei',
    href: 'https://eihei.net/',
    date: '2021-03-16',
    category: 'Social',
    summary: 'Hug our friends, host with love.',
    logo: Eihei,
  },
  {
    title: 'Lemonadev',
    href: 'https://lemona.dev/',
    date: '2022-12-24',
    category: 'Develop',
    summary: 'When life gives you lemons, make lemonade.',
    logo: Lemonadev,
  },
  // {
  //   title: 'Acolicy',
  //   href: 'https://acolicy.com/',
  //   date: '2020-04-04',
  //   category: 'Share',
  //   summary: 'Acolicy shares our collectibles.',
  //   logo: Acolicy,
  // },
  // {
  //   title: 'Xio',
  //   href: 'https://xio.moe/',
  //   date: '2021-08-31',
  //   category: 'Networking',
  //   summary: 'Xio takes devices into the larger and faster Internet.',
  //   logo: Xio,
  // },
];

export const PrivateServices: ServiceProps[] = [
  // {
  //   title: 'NyaPictures',
  //   href: 'https://nya.pictures/',
  //   date: '2021-11-21',
  //   category: 'Utility',
  //   summary: 'Another alternative image hosting service.',
  //   logo: NyaPictures,
  // },
  {
    title: 'NyaCodes',
    href: 'https://nya.codes/',
    date: '2022-02-16',
    category: 'Develop',
    summary: 'The safe bay where we can hold all codes with no worry.',
    logo: NyaCodes,
  },
  {
    title: 'NyaShow',
    href: 'https://nya.show/',
    date: '2022-07-24',
    category: 'Social',
    summary: 'Share our vision, host with love.',
    logo: NyaShow,
  },
  {
    title: 'NyaDisk',
    href: 'https://nyadisk.net/',
    date: '2022-11-27',
    category: 'Utility',
    summary: 'Safe and sweet home for file storaging.',
    logo: NyaDisk,
  },
];
