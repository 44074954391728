import Cloud3D from '@/asset/img/home/cloud3d.webp';

function Better() {
  return (
    <div className="relative bg-gray-900">
      <div className="relative h-56 bg-primary sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img className="h-full w-full object-cover" src={Cloud3D} alt="" />
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-gradient-to-r from-primary to-cyan-600 mix-blend-multiply"
        />
      </div>
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">Award winning attitude</h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Always willing to be better
          </p>
          <p className="mt-3 text-lg text-gray-300">
            No one is perfect, and neither are we. <br />
            But no worry, just strive to be better than yesterday.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Better;
