type iconProps = any;

export const footerNavigation: {
  links: {
    name: string;
    member: {
      name: string;
      href?: string;
      tag?: string;
    }[];
  }[];
  social: {
    name: string;
    href: string;
    icon: (props: iconProps) => JSX.Element;
  }[];
} = {
  links: [
    {
      name: 'Dept.',
      member: [{ name: 'Dev' }, { name: 'Com' }, { name: 'Net' }, { name: 'Org' }, { name: 'Xyz', tag: 'New' }],
    },
    {
      name: 'Services',
      member: [
        { name: 'NyaOne', href: 'https://nya.one/' },
        { name: 'Eihei', href: 'https://eihei.net/' },
        { name: 'Lemonadev', href: 'https://lemona.dev/' },
        { name: 'More...', href: '/service' },
      ],
    },
    {
      name: 'About Us',
      member: [
        { name: 'Team', href: '/team' },
        { name: 'Contact', href: '/contact' },
        // { name: 'Blog', href: '#' },
      ],
    },
    {
      name: 'Legal',
      member: [
        { name: 'Privacy', href: '/privacy' },
        // { name: 'Terms', href: '/terms' },
      ],
    },
  ],
  social: [
    {
      name: 'Misskey',
      href: 'https://nya.one/@nyawork',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 1024 1024" {...props}>
          <path d="M512,0C794.58,0 1024,229.42 1024,512C1024,794.58 794.58,1024 512,1024C229.42,1024 0,794.58 0,512C0,229.42 229.42,0 512,0ZM292.789,304.042L292.789,723.567C292.789,750.225 314.432,771.868 341.091,771.868C367.749,771.868 389.393,750.225 389.393,723.567L389.393,440.596L641.699,748.461C650.156,762.483 665.536,771.868 683.091,771.868C709.749,771.868 731.393,750.225 731.393,723.567L731.393,304.042C731.393,277.384 709.749,255.74 683.091,255.74C664.98,255.74 649.184,265.729 640.916,280.494L565.431,372.601L565.452,372.618C558.836,380.884 554.877,391.367 554.877,402.768C554.877,429.426 576.521,451.07 603.179,451.07C615.262,451.07 626.315,446.624 634.789,439.28L634.789,587.625L383.634,281.165C375.473,266.033 359.474,255.74 341.091,255.74C314.432,255.74 292.789,277.384 292.789,304.042Z" />
        </svg>
      ),
    },
    {
      name: 'NyaCodes',
      href: 'https://nya.codes/nyawork',
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="512"
          height="512"
          viewBox="0 0 512 512"
          fill="currentColor"
          {...props}
        >
          <rect data-element="frame" x="0" y="0" width="512" height="512" rx="512" ry="512" stroke="none"></rect>
          <g transform="translate(102.4 102.4) scale(0.6)" fill="#ffffff">
            <path d="M503.5 204.6l-.7-1.8-69.7-181.78c-1.4-3.57-3.9-6.59-7.2-8.64-2.4-1.55-5.1-2.515-8-2.81-2.9-.295-5.7.083-8.4 1.11-2.7 1.02-5.1 2.66-7.1 4.78-1.9 2.12-3.3 4.67-4.1 7.44l-47 144H160.8l-47.1-144c-.8-2.77-2.2-5.31-4.1-7.43-2-2.12-4.4-3.75-7.1-4.77a18.1 18.1 0 0 0-8.38-1.113 18.4 18.4 0 0 0-8.04 2.793 18.09 18.09 0 0 0-7.16 8.64L9.267 202.8l-.724 1.8a129.57 129.57 0 0 0-3.52 82c7.747 26.9 24.047 50.7 46.447 67.6l.27.2.59.4 105.97 79.5 52.6 39.7 32 24.2c3.7 1.9 8.3 4.3 13 4.3 4.7 0 9.3-2.4 13-4.3l32-24.2 52.6-39.7 106.7-79.9.3-.3c22.4-16.9 38.7-40.6 45.6-67.5 8.6-27 7.4-55.8-2.6-82z"></path>
          </g>
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: 'https://github.com/nyawork',
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="480"
          height="512"
          viewBox="0 0 480 512"
          fill="currentColor"
          {...props}
        >
          <rect data-element="frame" x="0" y="16" width="480" height="480" rx="480" ry="480" stroke="none"></rect>
          <g transform="translate(96 102.4) scale(0.6)" fill="#ffffff">
            <path d="M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 36.7-55.1-10.9-55.1-36.7-55.1z"></path>
          </g>
        </svg>
      ),
    },
  ],
};
