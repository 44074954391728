import {
  ArrowPathIcon,
  CheckBadgeIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
const features = [
  {
    name: 'CI/CD',
    description: 'Pre-configured CI/CD pipelines reduce time consumption on everything, except configuring themselves.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'HSTS Ready',
    description: 'HSTS is enabled on all of our sites. Preloading as soon as we can.',
    icon: LockClosedIcon,
  },
  {
    name: 'Backup 321',
    description: '3 total copies, 2 different mediums, 1 off-site. Precious data, handle with care.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced Security',
    description: "With key-pair authentication only for our servers, it's much safer than using a password.",
    icon: ShieldCheckIcon,
  },
  {
    name: 'Verified Certificates',
    description:
      "We award everything we built with a Works On My Machine badge. Otherwise, we don't even know if it works.",
    icon: CheckBadgeIcon,
  },
  {
    name: 'DevOps',
    description: 'We are all about DevOps. Codes first, commands in head. Linux is our best friend.',
    icon: ServerIcon,
  },
];

function Feature() {
  return (
    <div className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-cyan-600">Tech Stacks</h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Our Full-Stack Technology Map
        </p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          We use latest technology to build the best web applications. Most of them are self-taught and exercised
          through our developing journey.
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-gradient-to-r from-primary to-cyan-600 p-3 shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
