import Hero from '@/component/home/hero';

import NyaSay from '@/component/home/nyasay';
import Growing from '@/component/home/growing';
import Feature from '@/component/home/feature';
import Glance from '@/component/home/glance';
import Better from '@/component/home/better';

function Home() {
  return (
    <main>
      <Hero />

      <Growing />

      <Feature />

      <NyaSay />

      <Glance />

      <Better />
    </main>
  );
}

export default Home;
