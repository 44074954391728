import ServicesPart from '@/component/servicesPart';
import { Link } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';
import { OpenServices } from '@/component/servicesList';

const Glance = () => (
  <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
    <div className="relative">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-primary">Glances</h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Nya Powered Services</p>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">Have a quick glance of our ongoing services.</p>
      </div>

      <div className={'mt-12 flex '}>
        <ServicesPart services={OpenServices.slice(0, 3)} />
      </div>

      <div className="relative mt-12">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-dotted border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <Link
            to={'/service'}
            className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            <PlusIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span>See Full</span>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default Glance;
