export interface Navigation {
  name: string;
  href: string;
}

export const headerNavigation = [
  { name: 'Home', href: '/' },
  { name: 'Team', href: '/team' },
  { name: 'Service', href: '/service' },
];
