import { headerNavigation as navs } from '@/common/nav/header';

import { useState } from 'react';
import { Popover } from '@headlessui/react';
import DesktopNav from '@/component/nav/desktop';
import MobileNav from '@/component/nav/mobile';
import Nya from '@/component/modal/nya';

function Nav() {
  const [isModalOpen, setModalOpen] = useState(false);

  const hiNya = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Nya isOpen={isModalOpen} onClose={onModalClose} />
      <Popover as="header" className="relative">
        <DesktopNav navs={navs} nya={hiNya} />
        <MobileNav navs={navs} nya={hiNya} />
      </Popover>
    </>
  );
}

export default Nav;
