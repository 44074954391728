import { StrictMode } from 'react';
import ReactDOMClient from 'react-dom/client';
import './index.css';
import Layout from '@/layout';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!);
root.render(
  <StrictMode>
    <Layout />
  </StrictMode>,
);
