import ServicesPart from '@/component/servicesPart';
import { OpenServices, PrivateServices } from '@/component/servicesList';

function Service() {
  return (
    <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-24">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold uppercase tracking-wider text-primary">Services</h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Nya Powered Services</p>
      </div>

      <div className={'mt-12 flex flex-col'}>
        <h3 className="mx-auto mt-5 max-w-prose text-center text-xl text-gray-500">Open Services</h3>
        <ServicesPart services={OpenServices} />
      </div>

      <div className={'mt-12 flex flex-col'}>
        <h3 className="mx-auto mt-5 max-w-prose text-center text-xl text-gray-500">Other Services</h3>
        <ServicesPart services={PrivateServices} />
      </div>
    </div>
  );
}

export default Service;
